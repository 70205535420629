<template>
    <div class="content">
        <div class="h3 text-white font-weight-bold text-center my-5">
            <div>{{ $route.meta.title }}</div>
            <div class="cursor-pointer color-yellow text-center" style="font-size: 16px" @click="playingMethod = true">玩法介绍
            </div>
        </div>

        <div class="f-content-box d-flex flex-wrap p-md-3 bg-bg ">
            <div class="col-lg-4 col-md-6 col-12" style="height: 100%">
                <div style="margin-bottom:15px">
                    <div class="text-center my-3">
                        您将获得的皮肤价格区间
                    </div>
                    <div class="d-flex justify-content-between" style="font-size: 0.9rem">
                        <div class="" style="">
                            最低 <span class="h6 molvse">
                                <money />{{ (money.IntentionMoney * 0.5).toFixed(2) }}
                            </span>
                        </div>
                        <div>
                            ————————
                        </div>
                        <div>
                            最高 <span class="h6 molvse">
                                <money />{{ (money.IntentionMoney * 5).toFixed(2) }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="bg-bg-2 p-md-4 p-1">
                    <div class="d-flex justify-content-between flex-wrap" style="">
                        <div class="">
                            <span class="h5 price_bg" style="margin-right:5px">
                                {{ IntentionToSsynthetic.length }}/9
                            </span>
                            <span style="font-size: 12px">
                                件
                            </span>
                            <span style="font-size: 12px">总价</span>
                            <span class="h5 price_bg" style="margin-left:5px">
                                <money />{{ money.IntentionMoney.toFixed(2) }}
                            </span>

                        </div>
                        <div @click="SyntheticItemsAdd" class="cursor-pointer
                        f_btn_green text-center  ml-auto d-flex align-items-center" style=";font-size: 14px">
                            合约执行
                        </div>
                    </div>
                </div>

                <div class="d-flex bg-bg-2 flex-wrap px-md-4  px-14 pb-3 f-hechengwupinList">
                    <div class=" col-lg-4 col-md-4 col-4 mb-2 px-0 py-1" v-for="(item, index) in IntentionToSsynthetic"
                        :key="index + 'a'" @click="IntentionGun(item, index)" style="padding:0px!important;">
                        <div class="m-auto border100" style="width: 98%;height: 145px">
                            <img :src="item.cover" alt="" height="80%" class="f-content-img w-100">
                            <div class="text-center">
                                <money />{{ item.bean }}
                            </div>
                        </div>
                    </div>
                    <template v-if="IntentionToSsynthetic.length < 9">
                        <div class="col-lg-4 col-md-4 col-4 mb-2 px-0" style=""
                            v-for="(item, index) in (9 - IntentionToSsynthetic.length)" :key="index">
                            <div class="m-auto border100" style="width: 98%; height: 145px ">
                                <img :src="require('@/assets/img/commone/gun-bg.png')" alt="" height="80%"
                                    class="f-content-img w-100">
                            </div>
                        </div>
                    </template>
                </div>

            </div>
            <div class="col-lg-8 col-md-6 col-12">
                <div class="">
                    <div class="px-md-4 px-1"
                        style="border-top: 2px solid var(--f-main); background-image: linear-gradient(to bottom, #191926, #191926); ">
                        <div class="py-3 d-flex justify-content-between align-items-end" style="font-size: 20px;">
                            <div>添加 <b class="font-weight-bold">我的</b> 饰品</div>

                        </div>
                        <div class="mb-2">
                            您有{{ StorageList.length }}个皮肤
                        </div>
                        <div class="w-100 overflow-auto row p-md-3 p-1 m-auto position-relative"
                            :class="StorageList.length == 0 ? 'd-flex justify-content-center align-content-center' : ''"
                            style="background-color: #040319" :style="StorageList.length == 0 ? 'height:570px' : ''">
                            <div @click="weaponGun(item, index)" style=""
                                class="col-lg-2 col-md-3 col-4 col-4 px-1 compound-hove mb-2"
                                v-for="(item, index) in StorageList" :key="index">
                                <div :class="`position-relative border-${item.lv} ggbg${item.lv} p-2`"
                                    class="bg-size-100 bg-position-center">
                                    <div class="position-absolute bglogo-e"
                                        style="width: 60px; height: 60px;z-index:98;position:absolute;top:25%;left:25%;">
                                    </div>
                                    <div class="d-none">
                                        <el-checkbox v-model="checked"></el-checkbox>
                                    </div>
                                    <div class="">
                                        <img class="w-100" :src="item.cover" alt="">
                                    </div>
                                    <div class="d-flex justify-content-between" style="font-size:12px ">
                                        <div>
                                            <money />{{ item.bean }}
                                        </div>
                                        <div><i class="el-icon-eleme"></i></div>
                                    </div>

                                    <div
                                        class="position-absolute compound-hove-name top-0 left-0 right-0 bottom-0 px-3 cursor-pointer">
                                        <div class="d-flex justify-content-center align-items-center flex-column w-100"
                                            style="height: 100%;font-size: 12px">
                                            <div style="font-size: 14px" class="mb-2 font-weight-bold">
                                                {{ item.dura_alias }}
                                            </div>
                                            <div style="line-height: 1.5">{{ item.name }}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div v-if="StorageList.length == 0" class="position-absolute">
                                <div class="d-flex flex-column justify-content-center align-items-center w-100"
                                    style="height: 570px">
                                    <img class="filter-grayscale-1 " :src="require('@/assets/img/f_new/case-bg.png')"
                                        alt="">
                                    <div class="w-75 text-center mb-3">
                                        <div class="font-weight-bold h4">您的库存是空的！</div>
                                        <br />
                                        要将皮肤添加到您的库存中，您需要打开箱子，或者您可以使用余额获取所需的皮肤。
                                    </div>
                                    <router-link to="/" class="f-border px-5 py-3 text-center rounded-pill ">
                                        打开箱子
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize"
                            @current-change="currentChange">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!-- 玩法介绍 -->
        <div class="" v-if="playingMethod">
            <div style="width: 700px;left: calc(50% - 350px);top:15vh"
                class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg">
                <div class="text-white p-md-4 p-2" style="line-height: 2.0">

                    <p>1.选择饰品，使用您以前的饰品或购买我们提供的材料饰品进行合成。</p>

                    <p>2.合成获得随机饰品，饰品价格是合成总价的50%-200%</p>


                </div>
                <div @click="playingMethod = false" class="position-absolute" style="right: 20px;top: 30px">
                    <div>
                        <i class="iconfont text-white icon-guanbi-02-02  cursor-pointer" style="font-size:30px"></i>
                    </div>
                </div>

            </div>
            <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;"></div>
        </div>
        <!-- 合成弹窗 -->
        <div class="" v-if="svgaIsShow">
            <div style="width: 700px;left: calc(50% - 350px);top:15vh"
                class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg">
                <div>
                    <div class="d-flex flex-column align-items-center justify-content-center py-5 flex-wrap">
                        <div>
                            <img :src="compoundSucceed.cover" style="width: 300px;" alt="">
                        </div>
                        <div class="text-center">
                            <div>{{ compoundSucceed.name }}</div>
                            <div class="mt-2">{{ compoundSucceed.dura_alias }}</div>
                            <div class="mt-2">
                                <money />{{ compoundSucceed.bean }}
                            </div>

                        </div>
                    </div>
                    <div class="d-flex px-5 justify-content-center mt-3">
                        <div @click="svgaIsShow = false" style="width: 250px"
                            class=" text-white text-center f_btn_green cursor-pointer">
                            放入仓库
                        </div>
                    </div>
                    <div @click="svgaIsShow = false" class="position-absolute" style="right: 20px;top: 30px">
                        <div>
                            <i class="iconfont text-white icon-guanbi-02-02  cursor-pointer" style="font-size:30px"></i>
                        </div>
                    </div>

                </div>
            </div>
            <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;"></div>
        </div>
    </div>
</template>

<script>
import $api from '@/api/compound/index'
export default {
    name: "index",
    components: {
    },
    data() {
        return {
            checked: false,
            SyntheticItems: false,
            svgaIsShow: false,
            pitchYou: false,
            sideBoxList: [],
            StorageList: [],
            IntentionToSsynthetic: [],
            synthesisList: [],
            total: 0,
            pageSize: 0,
            totalSYN: 0,
            pageSizeSYN: 0,
            weaponGunCompound: '',
            money: {
                weaponMoney: 0,
                IntentionMoney: 0
            },
            IntentionWeponGun: {},
            compoundSucceed: {},
            svgaIsShowYuan: false,
            playingMethod: false
        }
    },
    computed: {
        moneyIntentionMoney: {
            get() {
                let sum = this.money.IntentionMoney * 5
                return sum.toFixed(2)
            },
            set(vla) {
                return vla
            }
        }
    },
    watch: {},
    created() {
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.get_box_hot()
            this.get_storage()
            this.get_synthesis_list()
        },
        // 初始化svga
        svgaInit(data) {
            let player = new this.$SVGA.Player('#SyntheticItems');
            let parser = new this.$SVGA.Parser('#SyntheticItems');
            parser.load('/svga/animated/SyntheticItems.svga', function (videoItem) {
                player.setVideoItem(videoItem);
                player.setImage(data.cover, 'img_22569')
                player.setText({
                    text: data.name,
                    family: 'Arial',
                    size: "24px",
                    color: "#ffe0a4",
                    offset: { x: 0.0, y: 0.0 }
                }, 'img_22569 ');
                player.loops = 1;
                player.clearsAfterStop = false;
                player.fillMode = 'Forward';
                player.startAnimation();
            })
        },
        SyntheticItemsList() {
            this.SyntheticItems = true

        },
        SyntheticItemsOff() {
            this.SyntheticItems = false
        },
        SyntheticItemsWeapon(item) {
            this.IntentionWeponGun = item
            this.weaponGunCompound = item.cover
            this.money.weaponMoney = item.bean
            this.pitchYou = true
            this.SyntheticItems = false
        },
        async SyntheticItemsAdd() {
            if (this.svgaIsShow == true) {
                this.svgaIsShowYuan = false
                this.IntentionToSsynthetic = []
                this.weaponGunCompound = ''
                this.money.IntentionMoney = 0
                this.money.weaponMoney = 0
                this.IntentionWeponGun.name = ''
                this.svgaIsShow = false
                return
            }
            try {
                let arr = []
                this.IntentionToSsynthetic.forEach(item => {
                    arr.push(item.id)
                })
                let compound = {
                    "in_ids": arr,
                }
                let data = await $api.get_synthesis_compound(compound)
                if (data.code == 500) return
                if (data.message != "OK") {
                    this.svgaIsShowYuan = true
                    setTimeout(() => {

                        this.compoundSucceed = {
                            cover: require('@/assets/img/commone/ZZ.png'),
                            name: '合成失败'
                        }
                        document.getElementById('error').play()
                        this.svgaIsShow = true
                        this.svgaIsShowYuan = false
                        this.IntentionToSsynthetic = []
                        this.weaponGunCompound = ''
                        this.money.IntentionMoney = 0
                        this.money.weaponMoney = 0
                        this.IntentionWeponGun.name = ''
                    }, 1000)
                } else {
                    this.svgaIsShowYuan = true
                    setTimeout(() => {
                        this.svgaIsShow = true
                        this.compoundSucceed = data.data
                        this.svgaIsShowYuan = false
                        this.IntentionToSsynthetic = []
                        this.weaponGunCompound = ''
                        this.money.IntentionMoney = 0
                        this.money.weaponMoney = 0
                        this.IntentionWeponGun.name = ''
                        document.getElementById('succeed').play()
                    }, 1000)
                }
                this.get_storage()

            } catch (e) {
                console.log(e)
            }

        },
        async get_box_hot() {
            try {
                let { data, code } = await $api.get_box_hot()
                if (code == 500) return
                this.sideBoxList = data
            } catch (e) {
                console.log(e)
            }
        },
        sideBox(item) {
            this.$router.push('/active/' + item.id)
        },
        async get_storage(page = 1) {
            try {
                let { data, code } = await $api.get_storage(page)
                if (code == 500) return
                this.StorageList = data.data
                this.total = data.total
                this.pageSize = data.per_page
            } catch (e) {
                console.log(e)
            }
        },
        currentChange(val) {
            this.get_storage(val)
            window.scrollTo(0, 0);
        },
        currentChangeSYN(val) {
            this.get_synthesis_list(val)
            window.scrollTo(0, 0);
        },
        // 点击个人仓库内的枪
        weaponGun(item, index) {
            if (this.IntentionToSsynthetic.length == 9) {
                this.$notify({
                    title: '警告',
                    message: '最多添加九件物品',
                    type: 'warning',
                    position: 'top-left'
                });
                return;
            }
            let indexGun = this.IntentionToSsynthetic.findIndex(i => {
                return i.id == item.id
            })
            if (indexGun != '-1') {
                this.$notify({
                    title: '警告',
                    message: '此物品已添加，请勿重复添加',
                    type: 'warning',
                    position: 'top-left'
                });
                this.StorageList.splice(index, 1)
                return
            }
            this.money.IntentionMoney += (item.bean) * 1
            this.IntentionToSsynthetic.push(item)
            this.StorageList.splice(index, 1)
            console.log(item, index)
        },
        IntentionGun(item, index) {
            this.money.IntentionMoney -= (item.bean) * 1
            this.StorageList.push(item)
            this.IntentionToSsynthetic.splice(index, 1)
        },
        async get_synthesis_list(val = 1) {
            try {
                let { data, code } = await $api.get_synthesis_list(val)
                if (code == 500) return
                this.synthesisList = data.data
                this.totalSYN = data.total
                this.pageSizeSYN = data.per_page
            } catch (e) {
                console.log(e)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.f_btn_green {
    padding: 10px 20px;
    border: 1px solid rgb(67, 132, 83);
    transition: all .5s;
    background-color: rgb(67, 132, 83);
    border-radius: 5px;

    @media (max-width:1000px) {
        margin: 8px 0;
    }

}

.f_btn_green:hover {
    background-color: #1a1a27;
    box-shadow: 0 0 5px rgba(67, 132, 83, .3);
    border: 1px solid rgb(117, 228, 144);
}

@media (max-width:1200px) {
    .f-hechengwupinList {
        /*height: 180px;*/
        /*overflow-y: scroll;*/
    }
}

.f-commone-gun {
    width: 20% !important;
}

@media (max-width:1200px) {
    .f-commone-gun {
        width: 30% !important;
        height: auto !important;
    }
}

@media (max-width:960px) {
    .f-commone-gun {
        width: 50% !important;
        height: auto !important;
    }
}

.up_left,
.up_right {
    width: calc(50% - 125px)
}

@media (max-width: 1250px) {

    .up_left,
    .up_right {
        width: 100%;
    }

    .up_conter {
        margin: 0 auto;
    }
}

.compound-hove {
    &-name {
        background-color: rgba(9, 8, 39, .6);
        display: none;
    }

    &:hover &-name {
        display: block;
    }
}

.caiqiedingbu {
    clip-path: polygon(0 0, calc(100% - 300px) 0, calc(100% - (300px - 40px)) 47px, 100% 47px, 100% 100%, 0 100%);
}

.up-pifu:nth-child(1) {
    transform: scale(.7);
}

.up-pifu:nth-child(2) {
    transform: scale(.8);
}

.up-pifu:nth-child(3) {
    transform: scale(.7);
}

.compound_add_bg {
    background: linear-gradient(270.39deg, hsla(0, 0%, 100%, .05) .34%, rgba(254, 253, 253, 0) 99.67%);
    clip-path: polygon(0 0, 75% 0, 100% 50%, 75% 100%, 0 100%);
    width: 106px;
    height: 106px;
    margin: 10px 10px;

    @media (max-width: 960px) {
        width: 90px;
        height: 90px;
    }
}

.bg-brown-con {
    background-color: rgba(25, 34, 45, .2);
    border: 1px solid rgba(25, 34, 45, 1);
}

/* 中间那个圆 */
.yuan {
    /*animation: pitchYouBox 5s infinite alternate;*/
    transition: all 5.5s cubic-bezier(0, 0, 0, 1);
    transform: rotate(3600deg)
}

/* 两侧的块 */
.rotateX {
    /*transition:all 5.5s cubic-bezier(0,0,0,1);*/
    /*transform: rotateX(720deg)*/
    /*animation: pitchYouBox 1s infinite alternate;*/
}

.heightt {
    /*height: 400px;*/
    /*width: 107%;*/
    /*height: 100%;*/
    padding: 10px;
    overflow: auto;
}

.hecheng {
    width: 100px;
    text-align: center;
    z-index: 9;
    height: 50px;
    background: rgba(0, 0, 0, .6);
    border-radius: 5px;
    line-height: 50px;
    margin: 0 auto;
}

.side-box {
    width: 200px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, .5)
}

.content-left {
    overflow: hidden;

    &-box {
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            /* Chrome去除滚动条 */
            display: none;
        }

        ;

        height: 360px;
        /*width: 107%;*/
        /*height: 100%;*/
        padding: 10px;
        overflow: auto;

        @media (max-width: 960px) {
            height: 150px;
        }
    }
}

.content-center {
    overflow: hidden;

    &-box {
        /*background:url("../../assets/img/commone/bg.png");*/
        height: 100%;
        width: 107%;
    }

    &-gun {
        width: 80%;
        top: 10%;
        left: 10%;
    }
}

.content-right {
    overflow: hidden;

    & div:nth-child(1) {
        width: 107%;
        height: 100%;
        padding: 10px;
        overflow: auto;
    }
}

.content {
    width: 90%;
    margin: 0 auto;

    @media (max-width: 1200px) {
        width: 100%;
    }

    &-explain {
        position: relative;
        padding-left: 20px;
        line-height: 1.4;
        font-size: 14px;

        &::before {
            content: '';
            width: 4px;
            height: 78px;
            background-color: yellow;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &-place {
        &-left {
            width: 720px;
            height: 200px;
            padding: 10px;
        }

        &-righr {
            width: 700px;
        }

    }
}

.pitchYou {
    animation: pitchYouGun 1.8s infinite alternate;
}

.pitchYouBox {
    transform: rotate(5deg)
        /*animation: pitchYouBox 1s infinite alternate;*/
}

@keyframes pitchYouGun {
    to {
        transform: translateY(-60px);
    }

    from {
        transform: translateY(20px);
    }
}

@keyframes pitchYouBox {
    0% {
        transform: rotate(360deg)
    }

    25% {
        transform: rotate(180deg)
    }

    50% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(-360deg)
    }
}</style>
