import {request} from "../index";

function get_box_hot() {
    return request({
        url: `/box/hot`,
        method:'get',
    })
}
function get_storage(page) {
    return request({
        url: `/storage?page=${page}`,
        method:'get',
    })
}

function get_synthesis_list(page) {
    return request({
        url: `/synthesis/list?page=${page}`,
        method:'get',
    })
}
function get_synthesis_compound(data) {
    return request({
        url: `/synthesis`,
        method:'post',
        data
    })
}

export default {
    get_box_hot,
    get_storage,
    get_synthesis_list,
    get_synthesis_compound,
}
